<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="channelmanagement" style="width:100vw">
  <div id="a0534b03">
    开发中列表
  </div>
  <div id="a77fbc1e">
    页面设计中～
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a0534b03{
  padding-top:100px;
}
#a0534b03{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a77fbc1e{
  margin-top:150px;
  padding-bottom:1342px;
}
#a77fbc1e{
  text-align:center;
  color:#c9c9c9;
  font-size:40px;
  font-family:PingFang SC;
}
/*在此注释下方添加自定义样式*/
</style>
